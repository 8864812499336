import { React } from "react";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import {
    AGENT_SERVER_URL,
} from "../../../config/config";
import { withRouter } from "react-router-dom";

import {
    listAgentAllowedServices,
    getAgentInfo,
    updateAgentAllowedServices,
} from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import HelperClass from "./../../../config/helperClass";
import makeAnimated from "react-select/animated";
import countryList from "react-select-country-list";
import { PhoneNumberUtil } from "google-libphonenumber";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const animatedComponents = makeAnimated();
const auth = new HelperClass();
class ViewAgent extends Component {
    constructor() {
        super();
        this.state = {
            serviceAssignedData: [],
            showServiceLoader: true,
            assignedLoader: true,
            checkAll: false,
            update_service_button: false,
            agent_accountno: "",
            client_update_name: "",
            client_comp_name: "",
            client_update_email: "",
            client_name_initials: "",
            client_status: "",
            assignedErrorMessage: "",
            assignedSuccessMessage: "",
            loginErrorMessage: "",
            login_url: "",
            activeTab: "assigned_products",
        };
        this.countryOptions = countryList()
            .getData()
            .filter(
                (country) => country.label !== "United States Minor Outlying Islands"
            );
        this.phoneInputRef = createRef();
    }

    async componentDidMount() {
        let agent_accountno = this.props.props.match.params.agent_accountno;
        this.setState({
            agent_accountno: agent_accountno,
        });
        const getClientInfoResponce = await getAgentInfo(
            auth.getAccount(),
            auth.getToken(),
            agent_accountno
        );
        console.log(
            "getClientInfoResponce.data.data : ",
            getClientInfoResponce.data
        );
        if (
            getClientInfoResponce.data.data.status === 403 ||
            getClientInfoResponce.data.data.errors === "authentication missing" ||
            getClientInfoResponce.data.data.errors === "jwt expired"
        ) {
            auth.logout();
        } else if (
            getClientInfoResponce.data.data.status === 404 ||
            getClientInfoResponce.data.data.note === "missing"
        ) {
            // window.location.replace("/error");
            console.log("settings missing");
            this.setState({
                tableLoader: false,
            });
        } else if (
            getClientInfoResponce.data.data.status === 200 &&
            getClientInfoResponce.data.data.message === "success"
        ) {
            const client_initials = getClientInfoResponce.data.data.name
                .split(" ")
                .map((word) => {
                    const firstChar = word.charAt(0);
                    return /^[a-zA-Z]+$/.test(firstChar) ? firstChar : "";
                })
                .join("");
            let kyc_percentage =
                (100 / getClientInfoResponce.data.data.steps_allowed) *
                getClientInfoResponce.data.data.steps_completed;

            const enableStatus =
                getClientInfoResponce.data.data.clientIntegrationPermission &&
                    getClientInfoResponce.data.data.clientIntegrationPermission.length > 0
                    ? getClientInfoResponce.data.data.clientIntegrationPermission[0]
                        .enable
                    : "No";
            const isEnableChecked = enableStatus === "Yes";

            const enableStatusMeta =
                getClientInfoResponce.data.data.clientIntegrationPermissionMeta &&
                    getClientInfoResponce.data.data.clientIntegrationPermissionMeta.length > 0
                    ? getClientInfoResponce.data.data.clientIntegrationPermissionMeta[0]
                        .enable
                    : "No";
            const isEnableCheckedMeta = enableStatusMeta === "Yes";

            this.setState({
                client_comp_name: getClientInfoResponce.data.data.comp_name,
                client_update_name: getClientInfoResponce.data.data.name,
                client_title: getClientInfoResponce.data.data.title,
                client_update_email: getClientInfoResponce.data.data.email,
                kyc_email_address: getClientInfoResponce.data.data.email,
                client_phone: getClientInfoResponce.data.data.phone,
                client_address: getClientInfoResponce.data.data.address,
                client_address2: getClientInfoResponce.data.data.address2,
                client_country: getClientInfoResponce.data.data.country,
                client_city: getClientInfoResponce.data.data.city,
                client_state: getClientInfoResponce.data.data.state,
                client_zip: getClientInfoResponce.data.data.zip,
                client_id: getClientInfoResponce.data.data.id,
                client_sharelink_limit: getClientInfoResponce.data.data.sharelink_limit,
                client_status: getClientInfoResponce.data.data.isVerified,
                client_name_initials: client_initials,
                totalClientAgreements: getClientInfoResponce.data.data.totalAgreements,
                signedClientAgreements:
                    getClientInfoResponce.data.data.signedAgreements,
                unsignedClientAgreements:
                    getClientInfoResponce.data.data.unsignedAgreements,
                totalSalesOrders: getClientInfoResponce.data.data.totalOrders,
                paidSalesOrders: getClientInfoResponce.data.data.paidOrders,
                completedSalesOrders: getClientInfoResponce.data.data.completedOrders,
                kyc_percentage: kyc_percentage.toFixed(0),
                client_isVerified: getClientInfoResponce.data.data.isVerified,
                loginDetails: getClientInfoResponce.data.data.agentLoginDetails,
            });
            //console.log("this.state.kyc_percentage: ", this.state.kyc_percentage);
        } else {
            // window.location.replace("/error");
        }
        this.showAssignedServices();
    }


    showAssignedServices = async () => {
        const { agent_accountno } = this.state;
        const servicesResponce = await listAgentAllowedServices(
            auth.getAccount(),
            auth.getToken(),
            agent_accountno
        );
        console.log("showAssignedServices: ", servicesResponce.data);
        if (
            servicesResponce.data.status === 403 ||
            servicesResponce.data.errors === "authentication missing" ||
            servicesResponce.data.errors === "jwt expired"
        ) {
            auth.logout();
        } else if (servicesResponce.data.status === 404) {
            this.setState({
                showServiceLoader: false,
                assignedLoader: false,
            });
            //window.location.replace("/error");
        } else if (
            servicesResponce.data.status === 200 &&
            servicesResponce.data.message === "success"
        ) {
            this.setState({
                serviceAssignedData: servicesResponce.data.data,
                showServiceLoader: false,
                assignedLoader: false,
            });
        } else {
            this.setState({
                showServiceLoader: false,
                assignedLoader: false,
            });
        }
    };

    handleCheckboxChange = (id) => {
        const serviceAssignedData = this.state.serviceAssignedData.map((checkbox) =>
            checkbox.id === id
                ? { ...checkbox, checked: !checkbox.checked }
                : checkbox
        );
        this.setState({ serviceAssignedData });
    };

    handleCheckAllChange = () => {
        const { checkAll, serviceAssignedData } = this.state;
        const updatedCheckboxes = serviceAssignedData.map((checkbox) => ({
            ...checkbox,
            checked: !checkAll,
        }));
        this.setState({
            serviceAssignedData: updatedCheckboxes,
            checkAll: !checkAll,
        });
    };

    updateAssignedServices = async () => {
        const { checkAll, serviceAssignedData, agent_accountno } = this.state;
        this.setState({
            update_service_button: true,
        });
        console.log(
            "updateAssignedServices serviceAssignedData: ",
            serviceAssignedData
        );
        const servicesResponce = await updateAgentAllowedServices(
            auth.getAccount(),
            auth.getToken(),
            agent_accountno,
            JSON.stringify(serviceAssignedData)
        );
        console.log("updateAssignedServices: ", servicesResponce.data);
        if (
            servicesResponce.data.status === 403 ||
            servicesResponce.data.errors === "authentication missing" ||
            servicesResponce.data.errors === "jwt expired"
        ) {
            auth.logout();
        } else if (servicesResponce.data.status === 404) {
            this.setState({
                update_service_button: false,
                assignedErrorMessage:
                    "There is some error while assigning the services to the channel partner.",
                assignedSuccessMessage: "",
            });
            //window.location.replace("/error");
        } else if (
            servicesResponce.data.status === 200 &&
            servicesResponce.data.message === "success"
        ) {
            this.setState({
                assignedErrorMessage: "",
                assignedSuccessMessage:
                    "Services / Products assigned successfully to the channel partner.",
                update_service_button: false,
            });
            setTimeout(() => {
                this.showAssignedServices();
            }, 3000);
        } else {
            this.setState({
                update_service_button: false,
                assignedErrorMessage:
                    "There is some error while assigning the services to the channel partner.",
                assignedSuccessMessage: "",
            });
        }
        setTimeout(() => {
            this.setState({
                update_service_button: false,
                assignedErrorMessage: "",
                assignedSuccessMessage: "",
            });
        }, 4000);
    };

    editClientModal = async () => {
        const { client_accountno } = this.state;
        const getClientInfoResponce = await getAgentInfo(
            auth.getAccount(),
            auth.getToken(),
            client_accountno
        );
        console.log(
            "getClientInfoResponce.data.data : ",
            getClientInfoResponce.data.data
        );
        if (
            getClientInfoResponce.data.data.status === 403 ||
            getClientInfoResponce.data.data.errors === "authentication missing" ||
            getClientInfoResponce.data.data.errors === "jwt expired"
        ) {
            auth.logout();
        } else if (
            getClientInfoResponce.data.data.status === 404 ||
            getClientInfoResponce.data.data.note === "missing"
        ) {
            // window.location.replace("/error");
            console.log("settings missing");
            this.setState({
                tableLoader: false,
            });
        } else if (
            getClientInfoResponce.data.data.status === 200 &&
            getClientInfoResponce.data.data.message === "success"
        ) {
            window.$("#modalUpdateClient").modal("show");
            this.setState({
                update_client_account_no: client_accountno,
                client_comp_name: getClientInfoResponce.data.data.comp_name,
                client_update_name: getClientInfoResponce.data.data.name,
                client_title: getClientInfoResponce.data.data.title,
                client_update_email: getClientInfoResponce.data.data.email,
                client_phone: getClientInfoResponce.data.data.phone,
                client_address: getClientInfoResponce.data.data.address,
                client_address2: getClientInfoResponce.data.data.address2,
                client_country: getClientInfoResponce.data.data.country,
                client_city: getClientInfoResponce.data.data.city,
                client_state: getClientInfoResponce.data.data.state,
                client_zip: getClientInfoResponce.data.data.zip,
                client_account_status: getClientInfoResponce.data.data.account_status,
                client_isVerified: getClientInfoResponce.data.data.isVerified,
            });
        } else {
            // window.location.replace("/error");
        }
        // console.log('edit client account_no : ',tableMeta.rowData[0]);
    };


    handleAddressSelect = async (place) => {
        const { description } = place.value;
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    description
                )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
            );
            const data = await response.json();

            if (data.status === "OK") {
                const addressComponents = data.results[0].address_components;
                const formattedAddress = data.results[0].formatted_address;
                let city = null;
                let state = null;
                let country = null;
                let zipCode = null;
                let streetAddress = "";
                let neighborhood = null;
                let locality = null;
                let address2 = "";
                addressComponents.forEach((component) => {
                    if (component.types.includes("neighborhood")) {
                        neighborhood = component.long_name;
                    } else if (component.types.includes("locality")) {
                        locality = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1")) {
                        state = component.short_name;
                    } else if (component.types.includes("country")) {
                        country = component.long_name;
                    } else if (component.types.includes("postal_code")) {
                        zipCode = component.long_name;
                    } else if (component.types.includes("subpremise")) {
                        address2 = component.long_name;
                    } else if (
                        ![
                            "neighborhood",
                            "sublocality",
                            "sublocality_level_1",
                            "sublocality_level_2",
                            "administrative_area_level_2",
                            "administrative_area_level_3",
                            "postal_code_suffix",
                        ].includes(component.types[0])
                    ) {
                        if (streetAddress !== "") {
                            streetAddress += " ";
                        }
                        streetAddress += component.long_name;
                    }
                });
                // Check if neighborhood is part of the formatted address
                if (neighborhood && formattedAddress.includes(neighborhood)) {
                    city = neighborhood;
                } else {
                    city = locality;
                }
                this.setState({
                    client_city: city && city !== "" ? city : "",
                    client_state: state && state !== "" ? state : "",
                    client_zip: zipCode && zipCode !== "" ? zipCode : "",
                    client_country: country && country !== "" ? country : "",
                    client_address:
                        streetAddress && streetAddress !== "" ? streetAddress : "",
                    client_address2: address2 && address2 !== "" ? address2 : "",
                });
            } else {
                console.error("Reverse geocoding request failed:", data.status);
            }
        } catch (error) {
            console.error("Error fetching reverse geocoding data:", error);
        }
    };

    phoneUtil = PhoneNumberUtil.getInstance();

    isPhoneValid = (phone) => {
        try {
            return this.phoneUtil.isValidNumber(
                this.phoneUtil.parseAndKeepRawInput(phone)
            );
        } catch (error) {
            return false;
        }
    };

    handleFocus = () => {
        // Move cursor to the end of the input value
        const input = this.phoneInputRef.current;
        if (input) {
            const value = input.value;
            input.setSelectionRange(value.length, value.length);
        }
    };


    addMenuHistory = (link) => {
        this.props.props.history.push(link);
    };

    render() {
        const { useGooglePlaces, toggleButtonText } = this.state;
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        const fetchToken = async () => {
            try {
                const response = await fetch(
                    AGENT_SERVER_URL + "agents/editor_token_url",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            request_type: "ckeditor-tokenUrl",
                            user: auth.getAccount(),
                        }),
                    }
                );
                const data = await response.json();
                return data.token;
            } catch (error) {
                console.error("Failed to fetch token:", error);
                return null;
            }
        };
        return (
            <div className="nk-content " id="Clients_Block">
                <div className="container-fluid">
                    {/* MSA WARNING  START*/}
                    {this.props.defaultSettings === "missing" &&
                        this.props.from !== "dashboard" ? (
                        <SettingsError />
                    ) : null}
                    {this.props.is_msa_signed === "No" &&
                        this.props.from !== "dashboard" ? (
                        <MsaWarning />
                    ) : null}
                    {/* MSA WARNING END */}

                    <div class="nk-content-inner">
                        <div class="nk-content-body">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-between g-3">
                                    <div class="nk-block-head-content">
                                        <h3 class="nk-block-title page-title">
                                            Channel Partner /{" "}
                                            <strong class="text-primary small">
                                                {this.state.client_update_name} (
                                                {this.state.client_comp_name})
                                            </strong>
                                        </h3>
                                        <div class="nk-block-des text-soft">
                                            <ul class="list-inline">
                                                <li>
                                                    Channel Partner ID:{" "}
                                                    <span class="text-base">
                                                        {this.state.agent_accountno}
                                                    </span>
                                                </li>
                                                {/*<li>
                          Last Login:{" "}
                          <span class="text-base">15 Feb, 2019 01:02 PM</span>
                        </li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nk-block-head-content">
                                        <a
                                            onClick={() => this.addMenuHistory("/agents")}
                                            style={{ cursor: "pointer" }}
                                            class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                                        >
                                            <em class="icon ni ni-arrow-left"></em>
                                            <span>Back</span>
                                        </a>
                                        <a
                                            onClick={() => this.addMenuHistory("/agents")}
                                            style={{ cursor: "pointer" }}
                                            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                                        >
                                            <em class="icon ni ni-arrow-left"></em>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="nk-block">
                                <div class="card card-bordered">
                                    <div class="card-aside-wrap clientsView">
                                        <div class="card-content">
                                            <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card viewClient_nav-tabs_customStyle">
                                                {/* <li
                                                    class="nav-item viewClients_nav-item_customStyle"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <a
                                                        className={`nav-link ${this.state.activeTab === "agreements"
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={(e) => {
                                                            this.changeActiveTab("agreements");
                                                            this.agentAgreements();
                                                        }}
                                                    >
                                                        <em class="icon ni ni-file-text"></em>
                                                        <span>Agreements</span>
                                                    </a>
                                                </li> */}
                                                <li
                                                    class="nav-item viewClients_nav-item_customStyle"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <a
                                                        className={`nav-link ${this.state.activeTab === "assigned_products"
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={(e) => {
                                                            this.changeActiveTab("assigned_products");
                                                            this.showAssignedServices();
                                                        }}
                                                    >
                                                        <em class="icon ni ni-file-text"></em>
                                                        <span>Assigned Products</span>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div class="tab-content">
                                                <div
                                                    className={
                                                        this.state.activeTab === "assigned_products"
                                                            ? "tab-pane active"
                                                            : "tab-pane"
                                                    }
                                                    id="tabItemProducts"
                                                >
                                                    <div class="card-inner">
                                                        <div class="nk-block">
                                                            <div class="nk-block-head">
                                                                <h5 class="title">
                                                                    Assigned Products & Services
                                                                </h5>
                                                                <p>
                                                                    This list comprises the products or services
                                                                    allocated or permitted for this channel partner.
                                                                </p>
                                                            </div>
                                                            {this.state.assignedErrorMessage !== "" ? (
                                                                <div class="alert alert-pro alert-danger">
                                                                    <div class="alert-text">
                                                                        <h6>Error</h6>
                                                                        <p>{this.state.assignedErrorMessage}</p>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            {this.state.assignedSuccessMessage !== "" ? (
                                                                <div class="alert alert-pro alert-success">
                                                                    <div class="alert-text">
                                                                        <h6>Success</h6>
                                                                        <p>{this.state.assignedSuccessMessage}</p>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            {this.state.assignedLoader === true ? (
                                                                FormLoader()
                                                            ) : (
                                                                <div class="table-responsive">
                                                                    {this.state.serviceAssignedData &&
                                                                        this.state.serviceAssignedData.length > 0 ? (
                                                                        <>
                                                                            <table class="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    checked={this.state.checkAll}
                                                                                                    class="custom-control-input"
                                                                                                    name="selected_products_1"
                                                                                                    onChange={
                                                                                                        this.handleCheckAllChange
                                                                                                    }
                                                                                                    id="product_ids"
                                                                                                />
                                                                                                <label
                                                                                                    class="custom-control-label"
                                                                                                    htmlFor="product_ids"
                                                                                                ></label>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th>Item</th>
                                                                                        <th>SKU</th>
                                                                                        <th>Title</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.serviceAssignedData.map(
                                                                                        (row, index) => (
                                                                                            <tr key={`prokey${index}`}>
                                                                                                <td>
                                                                                                    <div class="custom-control custom-checkbox">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            checked={row.checked}
                                                                                                            class="custom-control-input"
                                                                                                            name="selected_products"
                                                                                                            onChange={() =>
                                                                                                                this.handleCheckboxChange(
                                                                                                                    row.id
                                                                                                                )
                                                                                                            }
                                                                                                            value={row.id}
                                                                                                            id={`product_id_${index}`}
                                                                                                        />
                                                                                                        <label
                                                                                                            class="custom-control-label"
                                                                                                            htmlFor={`product_id_${index}`}
                                                                                                        ></label>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{row.id}</td>
                                                                                                <td>
                                                                                                    {row.sku && row.sku !== ""
                                                                                                        ? row.sku
                                                                                                        : "---"}
                                                                                                </td>
                                                                                                <td>{row.service_title}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-sm-12 mt-4 d-flex justify-content-end">
                                                                                <div class="nk-block-head-content">
                                                                                    <ul class="nk-block-tools gx-3">
                                                                                        {this.state
                                                                                            .update_service_button ===
                                                                                            true ? (
                                                                                            <button
                                                                                                className="btn btn-primary"
                                                                                                type="button"
                                                                                                disabled
                                                                                            >
                                                                                                <span
                                                                                                    className="spinner-grow spinner-grow-sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                ></span>
                                                                                                <span> Processing... </span>
                                                                                            </button>
                                                                                        ) : (
                                                                                            <li>
                                                                                                <a
                                                                                                    onClick={() =>
                                                                                                        this.updateAssignedServices()
                                                                                                    }
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        color: "#fff",
                                                                                                    }}
                                                                                                    class="btn btn-primary"
                                                                                                >
                                                                                                    <em class="icon ni ni-check-round-cut"></em>{" "}
                                                                                                    <span>Update</span>{" "}
                                                                                                </a>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div class="alert alert-pro alert-primary">
                                                                            <div class="alert-text">
                                                                                <h6>No Service Assigned</h6>
                                                                                <p>
                                                                                    No service has been assigned to this
                                                                                    client.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="card-aside card-aside-right user-aside"
                                            data-content="userAside"
                                            data-toggle-screen="xxl"
                                            data-toggle-overlay="true"
                                            data-toggle-body="true"
                                        >
                                            <div class="card-inner-group" data-simplebar>
                                                <div class="card-inner">
                                                    <div class="user-card user-card-s2">
                                                        <div class="user-avatar lg bg-primary">
                                                            <span>{this.state.client_name_initials}</span>
                                                        </div>
                                                        <div class="user-info">
                                                            {this.state.client_status &&
                                                                this.state.client_status === "Yes" ? (
                                                                <div class="badge badge-outline-success badge-pill ucap">
                                                                    Verified
                                                                </div>
                                                            ) : (
                                                                <div class="badge badge-outline-danger badge-pill ucap">
                                                                    Not Verified
                                                                </div>
                                                            )}
                                                            <h5>{this.state.client_update_name}</h5>
                                                            <span class="sub-text">
                                                                {this.state.client_update_email}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        defaultSettings: state.settings,
        is_msa_signed: state.is_msa_signed,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addSettings: (payload) => dispatch(addSettings(payload)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewAgent));
